<template>
	<div class="row justify-around q-px-sm">
		<q-btn
			v-for="btn in btn_data"
			:key="btn.label"
			outline
			rounded
			stack
			class="col-sm-3 col-xs-12 q-ma-md"
			color="primary"
			size='md'
			:icon='btn.icon'
			:label="btn.label"
			:to="btn.to"
			@click="collapse_sidebar_item({
				'sidebar_parent_id': btn.sidebar_parent_id,
				'sidebar_child_id': btn.sidebar_child_id
			})"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	name: "DashboardTabs",
	data(){
		return{
			btn_data: [],
		}
	},
	computed: {
		...mapGetters(['logged_user'])
	},
	methods:{
		...mapActions(['collapse_sidebar_item']),
		createBtnData(){
			this.btn_data.push({
				'label': 'Send Message',
				'icon': 'send',
				'to': {'name': 'Send Message'},
				'sidebar_parent_id': 2,
				'sidebar_child_id': 202,
			})
			if (this.logged_user.customer_permission != 'SENDER'){
				this.btn_data.push({
					'label': 'Create Message',
					'icon': 'message',
					'to': {'name': 'Create Message'},
					'sidebar_parent_id': 2,
					'sidebar_child_id': 201,
				})
			}
			this.btn_data.push({
				'label': 'Reports',
				'icon': 'pie_chart',
				'to': {'name': 'Message Reports'},
				'sidebar_parent_id': 2,
				'sidebar_child_id': 205,
			})
		}
	},
	created(){
		this.createBtnData()
	}
}
</script>
